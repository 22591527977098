import moment from "moment";
import alertIcon from "../../../assets/img/icons/cards/alert-icon.svg";

import './stagedCardLayout.scss';

interface StagedCardLayoutProps {
  children: React.ReactNode;
  datetime?: any | null;
  time?: string;
  cardIcon: string;
  cardTitle: string;
  isPublished: boolean;
  isServiceDeclined: boolean;
  serviceDeclined?: string;
  isMissingInfo: boolean;
}

const StagedCardLayout = ({ children,  cardIcon, cardTitle, datetime, time, isPublished, isServiceDeclined,  serviceDeclined, isMissingInfo }: StagedCardLayoutProps) => {
  const convertedDateTime = moment(datetime).format('MMMM Do YYYY') || null;

  return (
    <div className="stage-one-card-container">
      <div className="stage-one-card-icon" style={{ marginTop: datetime ? '2.5rem' : '2.5rem' }}>
        <img
          loading="lazy"
          src={cardIcon}
          className="svg-left"
          alt="icon"
        />
      </div>
      <div className="stage-one-card-content" style={{ marginTop: datetime ? '0rem' : '2rem' }}>
        {datetime && <div className="stage-one-card-date">{convertedDateTime}</div>}
        <div className="stage-one-card-header">
          <div className="stage-one-card-header-icon">
            <img
              loading="lazy"
              src={cardIcon}
              className="svg-main"
              alt="icon"
            />
          </div>
          <div className="stage-one-card-details">
            <div className="stage-one-card-title-container">
              <span style={{ wordBreak: 'break-word', whiteSpace: 'normal' }}>{cardTitle}</span>
              {isMissingInfo && 
                <div className="missing-info">
                  <img
                    loading="lazy"
                    src={alertIcon}
                    className="img"
                    alt="exclamation-icon"
                  />
                  <div className="">Missing Info</div>
                </div>
              }
              
              {serviceDeclined === 'DECLINED' && !isPublished && 
                <div className="missing-info">
                  <img
                    loading="lazy"
                    src={alertIcon}
                    className="img"
                    alt="exclamation-icon"
                  />
                  <div className="">Service Declined</div>
                </div>
              }
              
              {isPublished && 
                <div className="stage-one-card-time-container">
                  <span className="stage-one-card-time-span">
                    {time && time !== "Invalid date" ? time : null}
                  </span>
                </div>
              }
            </div>

            {isMissingInfo && 
              <p className="missing-info-text">
                We need more details from you to coordinate this part of your experience.
              </p>
            }
          </div>
          
          <div className="divider"></div>

          <div className="card-content">
            {children}
          </div>
        </div>
      </div>
    </div>
  );
};

export default StagedCardLayout;
