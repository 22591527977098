import GooglePlacesAutocomplete, {
  geocodeByAddress,
  getLatLng,
} from "react-google-places-autocomplete";
import mapIcon from "../../assets/img/icons/cards/map-icon.svg";

import "./select.scss";

const GooglePlaceAutocompleteInput = ({
  cardState,
  setCardState,
  placeLabel,
  placeName,
  placeValueName,
  placeLinkName,
  isInvalid,
  cardStateField,
}: any) => {
  const cardValue = cardState.cartData?.[placeName]
    ? {
        label: placeLabel === 'airportName' ? cardState.cartData?.[placeLabel] : cardState.cartData?.[placeName],
        value: JSON.stringify(cardState.cartData?.[placeValueName]),
      }
    : "";

  const cardStateFieldValue = cardState.cartData?.[cardStateField]?.[placeName]
    ? {
        label: placeLabel === 'airportName1' || placeLabel === 'airportName2' ? cardState.cartData[cardStateField][placeLabel] : cardState.cartData[cardStateField][placeName],
        value: JSON.stringify(cardState.cartData[cardStateField][placeValueName]),
      }
    : "";

  const handleCardChange = (place: any) => {
    if (place?.label) {
      geocodeByAddress(place.label)
        .then((results) => getLatLng(results[0]))
        .then(({ lat, lng }) => {
          const placeLink = `https://www.google.com/maps/search/?api=1&query=${lat}%2C${lng}&query_place_id=${place?.value?.place_id}`;
 
          if (cardStateField) {
            setCardState((state: any) => {
              return {
                ...state,
                cartData: {
                  ...state.cartData,
                  [cardStateField]: {
                    ...state.cartData[cardStateField],
                    ...(placeLabel && {
                      [placeLabel]: place.value.structured_formatting.main_text.replaceAll("'", "’")
                    }),
                    [placeName]: place.label.replaceAll("'", "’"),
                    [placeLinkName]: placeLink,
                    [placeValueName]: JSON.stringify(place).replaceAll("'", "’"),
                  },
                },
              };
            });
          } else {
            setCardState((state: any) => {
              return {
                ...state,
                cartData: {
                  ...state.cartData,
                  ...(placeLabel && {
                    [placeLabel]: place.value.structured_formatting.main_text.replaceAll("'", "’")
                  }),
                  [placeName]: place.label.replaceAll("'", "’"),
                  [placeLinkName]: placeLink,
                  [placeValueName]: JSON.stringify(place).replaceAll("'", "’"),
                },
              };
            });
          }
        })
        .catch((error) => console.error(error));
    } else {
      if (cardStateField) {
        setCardState((state: any) => {
          return {
            ...state,
            cartData: {
              ...state.cartData,
              [cardStateField]: {
                ...state.cartData[cardStateField],
                ...(placeLabel && {
                  [placeLabel]: "",
                }),
                [placeName]: "",
                [placeLinkName]: "",
                [placeValueName]: "",
              },
            },
          };
        });
      } else {
        setCardState((state: any) => {
          return {
            ...state,
            cartData: {
              ...state.cartData,
              ...(placeLabel && {
                [placeLabel]: "",
              }),
              [placeName]: "",
              [placeLinkName]: "",
              [placeValueName]: "",
            },
          };
        });
      }
    }
  };

  return (
    <div className="autocomplete-container">
      <img src={mapIcon} alt="Map Icon" className="map-icon" />
      <GooglePlacesAutocomplete
        apiKey="AIzaSyBotJSxfPV9-iBS1sfHgia__HWDlDlBVNg"
        selectProps={{
          value: cardStateField ? cardStateFieldValue : cardValue,
          isClearable: true,
          onChange: handleCardChange,
          styles: {
            control: (base: any) => ({
              ...base,
              margin: "0",
              // maxWidth: "296px",
              width: "100%",
              display: "flex",
              borderRadius: "0.25rem",
              padding: "0 8px 0 32px",
              borderColor: isInvalid ? "#ce4275" : "#dee2e6",
              boxShadow: "none",
              "&:hover": {
                borderColor: "none",
              },
            }),
            input: (base: any) => ({
              ...base,
              marginBottom: "10px",
            }),
            // indicatorsContainer: (base: any) => ({
            //   ...base,
            //   display: "none",
            // }),
          },
          placeholder: "Search Address",
        }}
      />
    </div>
  );
};

export default GooglePlaceAutocompleteInput;
